import { CrudField, CrudFieldQuery, ICrudField } from "../CrudField";
import { CrudFilter } from "../CrudFilter";
import { CrudModel } from "../CrudModel";
import { CrudPropertyFilter } from "../filters";
import { IModalOptions } from "../layouts/CollectionLayout";
import { RelationshipProperty } from "../property-types/RelationshipProperty";

export interface IRelationshipField extends ICrudField {
  allowCreateNew?: boolean;
  modalOptions?: IModalOptions;
  staticPropertyFilters?: Record<string, any>;
  filters?: CrudFilter[];
}
export class RelationshipField extends CrudField {
  public static fieldName = "RelationshipField";
  public static formComponentName: string = "form-relationship-field";
  public static viewComponentName: string = "view-relationship-field";
  protected static readonly defaultPropertyType = RelationshipProperty;

  public static afterLabelComponents = [
    "relationship-link-icon",
    // "relationship-modal-button",
  ];
  public modalOptions: IModalOptions = {
    layoutId: "",
    readmodeDefault: true,
  };

  public allowCreateNew = true;
  public filters: CrudFilter[] = [];

  constructor(opts: IRelationshipField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.allowCreateNew !== "undefined")
      this.allowCreateNew = opts.allowCreateNew;
    else this.allowCreateNew = this.$nuxt.$config.defaultRelationshipCreateNew;

    if (typeof opts.modalOptions !== "undefined")
      Object.assign(this.modalOptions, opts.modalOptions);

    if (typeof opts.filters !== "undefined")
      this.filters = [...this.filters, ...opts.filters];

    if (typeof opts.staticPropertyFilters !== "undefined")
      this.filters = [
        ...this.filters,
        ...(this.property as RelationshipProperty).relatedModel
          .findProperties(Object.keys(opts.staticPropertyFilters))
          .map((property) => {
            const filter = CrudPropertyFilter.fromProperty(property);
            filter.setQuery(opts.staticPropertyFilters![property.name]);
            return filter;
          }),
      ];
  }

  private _stripRootField(fieldString: string) {
    const fieldPieces = fieldString.split(".");
    fieldPieces.shift();
    return fieldPieces.join(".");
  }

  private _getRootField(fieldString: string) {
    return fieldString.split(".")[0];
  }

  public findFields(
    fieldArg: CrudFieldQuery[] | CrudFieldQuery
  ): CrudField[] | undefined {
    if (!Array.isArray(fieldArg)) fieldArg = [fieldArg];

    if (super.findFields(fieldArg)) return [this as CrudField];

    if (fieldArg.some((field) => field == this.id)) return [this as CrudField];

    const fieldArgsStringsWithoutRootField = fieldArg
      .filter((arg) => typeof arg === "string")
      .filter((stringArg) => this._getRootField(stringArg as string) == this.id)
      .map((stringArg) => this._stripRootField(stringArg as string))
      .filter((arg) => arg);

    if (fieldArgsStringsWithoutRootField.length > 0) {
      const modelFields = (
        this.property as RelationshipProperty
      ).modelInstance.findFields(fieldArgsStringsWithoutRootField);
      return modelFields;
    }

    return undefined;
  }

  public getRelativeLabel(fieldQuery: CrudFieldQuery, prefix = "") {
    const fields = this.findFields(fieldQuery);

    if (!fields) return "";
    if (fields.length > 1)
      console.error(
        `RelField.getRelativeLabel() returned more than 1 result.
        Check for duplicated property names or use a more specific query.`,
        fieldQuery,
        fields
      );

    const field = fields.pop() as CrudField;
    const thisLabel = super.getRelativeLabel(fieldQuery, prefix);
    if (field === this) return thisLabel;

    if (typeof fieldQuery != "string") return fieldQuery.label;

    const nextLevelFieldQuery = fieldQuery.split(".").slice(0, 2).join(".");
    const nextLevelFieldResults = this.findFields(nextLevelFieldQuery);
    if (!nextLevelFieldResults) return "";
    if (nextLevelFieldResults.length > 1)
      console.error(
        `RelFieldMany.getRelativeLabel() returned more than 1 result.
        Check for duplicated property names or use a more specific query.`,
        fieldQuery,
        fields
      );

    const nextLevelField = nextLevelFieldResults.pop() as CrudField;
    return nextLevelField.getRelativeLabel(
      this._stripRootField(fieldQuery as string),
      thisLabel
    );
  }

  public get modalProps(): Record<string, any> {
    return {
      "model-layout-id": this.modalOptions.layoutId,
      "readmode-default": this.modalOptions.readmodeDefault,
      title: this.modalOptions.title,
    };
  }

  public get modelInstance() {
    return (this.property as RelationshipProperty).modelInstance;
  }

  public get instanceLabel() {
    return this.modelInstance.label;
  }

  public createNew(opts) {
    return (this.property as RelationshipProperty).createNew(opts);
  }

  public clear() {
    this.set(null);
  }
}
