import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class CourseTag extends CrudModel {
  protected static _typeLabel = "Course Tag";
  protected static asProperty = "course_tag";
  public static api = {
    path: "course-tags",
    fullCache: true,
  };

  protected userIdField = [];
  protected static routeBase = "course-tags";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: StringProperty,
      opts: { name: "name", label: "Name", rules: ["required"] },
    },

    // relationships

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "courses",
        label: "Tagged Courses",
        relatedModel: "Course",
        foreignProperty: "course_tags",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: StringField,
      opts: { property: "name", isPrimaryLabelField: 1 },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "courses",
        collectionLayoutOpts: {
          enableSearch: false,
          noRefresh: true,
          noNew: true,
          enableDynamicFilters: false,
          collectionActionComponents: [""],
        },
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: ["name"],
      filterFields: [],
      collectionActionComponents: ["collection-export"],
      searchLabel: "Search",
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
  ]);

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "name",
          fields: ["name", "courses"],
        },
      ],
    },
  ];
}
