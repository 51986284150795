import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { BooleanField } from "../plugins/Cruxtify/field-types/BooleanField";
import { NumberField } from "../plugins/Cruxtify/field-types/NumberField";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  BooleanProperty,
  NumberProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import { GyroMethodField } from "../fields/GyroMethodField";
import { LongTextField, WysiwygField } from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";

export class Course extends CrudModel {
  protected static _typeLabel = "Course";
  protected static asProperty = "course";
  public static api = {
    path: "courses",
    // fullCache: true,
  };

  protected userIdField = [];
  protected static routeBase = "courses";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: { name: "name", label: "Name", rules: ["required"] },
    },
    {
      type: StringProperty,
      opts: { name: "description", label: "Description" },
    },
    {
      type: StringProperty,
      opts: { name: "conducted_by", label: "Conducted by" },
    },
    {
      type: StringProperty,
      opts: { name: "duration", label: "Duration" },
    },
    {
      type: StringProperty,
      opts: { name: "cost_default", label: "Cost Default" },
    },
    {
      type: BooleanProperty,
      opts: { name: "is_legacy", label: "Legacy Course" },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "attestation_enabled",
        label: "Enable Attestation Generation",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "email_message",
        label: "Email Message",
        description:
          "When attestation is enabled, the message in the field will be available in the attestation email template with the [COURSE_MESSAGE] merge tag.",
      },
    },
    {
      type: StringProperty,
      opts: { name: "email_subject", label: "Email Subject" },
    },
    {
      type: StringProperty,
      opts: { name: "method_type", label: "Method Type" },
    },
    {
      type: NumberProperty,
      opts: { name: "order", label: "Order" },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_only_admin_schedulable",
        label: "Only Schedulable by Admins",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "attestation_name",
        label: "Formatted Course Name on Attestation",
        description:
          "If not blank, this is how the name of the Course will appear on the Attestation (useful for line breaks or other deviations from the Database name.)",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "attestation_additional_text",
        label: "Additional Text on Attestation",
        description:
          "This text will appear on the bottom left of the PDF (e.g. NPCP CECs: 15.00, NPCP Course #: 28759-3001, Issue Date: 5-24-2021)",
      },
    },

    // relationships

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "instructors",
        label: "Only allowed Trainers that can schedule events for this course",
        description:
          "If left blank, any trainer can schedule events for this course if they have the appropriate qualifications.",
        relatedModel: "Trainer",
        foreignProperty: "instructor_of",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "qualifications",
        label: "Qualifications required to teach this course",
        description: "This will be ignored if specific trainers are selected.",
        relatedModel: "Qualification",
        foreignProperty: "courses",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "course_categories",
        label: "Course Categories",
        relatedModel: "CourseCategory",
        foreignProperty: "courses",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "notifications",
        label: "Notifications",
        relatedModel: "Notification",
        foreignProperty: "course",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "resources",
        label: "Related Resources",
        relatedModel: "CourseAccessibleResource",
        foreignProperty: "course",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "course_costs",
        label: "Costs",
        relatedModel: "CourseCost",
        foreignProperty: "course",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "course_tags",
        label: "Tags",
        relatedModel: "CourseTag",
        foreignProperty: "courses",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: StringField,
      opts: { property: "name", isPrimaryLabelField: 1, md: 6 },
    },
    {
      type: WysiwygField,
      opts: { property: "description" },
    },
    {
      type: StringField,
      opts: { property: "conducted_by" },
    },
    {
      type: StringField,
      opts: { property: "duration" },
    },
    {
      type: StringField,
      opts: { property: "cost_default" },
    },
    {
      type: BooleanField,
      opts: { property: "is_legacy" },
    },
    {
      type: BooleanField,
      opts: { property: "attestation_enabled" },
    },
    {
      type: WysiwygField,
      opts: { property: "email_message" },
    },
    {
      type: StringField,
      opts: {
        property: "email_subject",
        cols: 12,
        conditional: {
          property: "attestation_enabled",
          compare: "notEquals",
        },
      },
    },
    {
      type: GyroMethodField,
      opts: { property: "method_type" },
    },
    {
      type: NumberField,
      opts: { property: "order", UserPermission: UserPermission.Hidden },
    },
    {
      type: BooleanField,
      opts: {
        property: "is_only_admin_schedulable",
      },
    },
    {
      type: LongTextField,
      opts: {
        property: "attestation_name",
        conditional: {
          property: "attestation_enabled",
        },
      },
    },
    {
      type: StringField,
      opts: {
        property: "attestation_additional_text",
        cols: 12,
        conditional: {
          property: "attestation_enabled",
        },
      },
    },
    {
      type: StringField,
      opts: {
        label: "",
        property: "name",
        id: "merge_tags",
        viewComponentName: "merge-tags-reference",
        formComponentName: "merge-tags-reference",
      },
    },

    // {
    //   type: RelationshipFieldMany,
    //   opts: { property: "users" }
    // },

    {
      type: RelationshipFieldMany,
      opts: { property: "instructors" },
    },

    {
      type: RelationshipFieldMany,
      opts: { property: "qualifications" },
    },

    {
      type: RelationshipFieldMany,
      opts: { property: "course_categories" },
    },

    {
      type: RelationshipFieldMany,
      opts: {
        property: "course_costs",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "notifications",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "course_tags",
      },
    },
  ];

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      ordinalField: "order",
      columnFields: ["name", "method_type", "course_tags", "course_categories"],
      filterFields: ["course_tags"],
      collectionActionComponents: ["collection-export"],
      searchLabel: "Search by name",
      enableSearch: true,
      defaultSort: [
        {
          field: "order",
          order: "asc",
        },
        // {
        //   field: "step_num",
        //   order: "asc"
        // }
      ],
    },
  ]);

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "Crudform",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                id: "default",
                fieldSets: [
                  {
                    id: "name",
                    fields: [
                      "name",
                      "method_type",
                      "duration",
                      "order",
                      "course_categories",
                      "course_tags",
                    ],
                  },
                  {
                    id: "about",
                    fields: [
                      "conducted_by",
                      "is_legacy",
                      "description",
                      "cost_default",
                      "course_costs",
                    ],
                  },
                  {
                    id: "meta",
                    fields: ["id", "created_at", "updated_at"],
                  },
                ],
              },
            },
          },
          {
            id: "CompletiinTab",
            label: "Completion Settings",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "attestation",
                    fields: [
                      "attestation_enabled",
                      "attestation_name",
                      "attestation_additional_text",
                    ],
                  },
                  {
                    id: "email",
                    fields: ["merge_tags", "email_subject", "email_message"],
                  },
                ],
              },
            },
          },
          {
            id: "AccessTab",
            label: "Access Settings",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "access",

                    fields: [
                      "is_only_admin_schedulable",
                      "instructors",
                      "qualifications",
                    ],
                  },
                ],
              },
            },
          },
          {
            id: "NotificationsTab",
            label: "Notifications",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "notifications",
                    fields: ["notifications"],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];
}
